import M1 from '../assets/media/m1.jpg'
import M2 from '../assets/media/m2.jpg'
import M3 from '../assets/media/m3.jpg'
import M4 from '../assets/media/m4.jpg'
import M5 from '../assets/media/m5.jpg'
import Clients from '../components/Clients'

const Services = () => {
  return (
    <div className="main-content-elements">
        <div className="sub-page-headers services-pg">
        </div>
        <div className="sub-page-content">
          <section className="row tm-section services-details">
            <h2 className="tm-section-title tm-color-primary mb-4">SERVICES</h2>
            <p>
              The Company manages all aspects of the supply chain from inbound sourcing and delivery logistics through outbound shipment, handling, consolidation, deconsolidation, distribution, and delivery of end products. 
            </p>
            <p>
              Through our integrated transportation management system, we provide clients with cargo planning, tendering, shipment visibility and configurable event management, freight audit and payment, and management reporting.  
            </p>
            <br/>
      
            <h3 className="air-cargo-hand tm-color-primary mb-4">Air Cargo Handling</h3>
            <p>
              Primus Global provides GSA cargo services for airlines operating in and outside of Ghana. 
            </p>
            <br />
            <h3 className="tm-color-primary mb-4">Freight Forwarding and Customs Brokerage</h3>
            <p>
              Primus Global handles all the necessary documentation regarding a client’s forwarding business and also does pick-ups and delivery of cargo from client’s designated premises to the desired destination.
            </p>
            <br />
            <h3 className="tm-color-primary mb-4">Supply Chain Management</h3>
            <p>
              Primus Global handles clients’ purchases both locally and abroad using our network of partners worldwide, this gives our clients the best quality at the most competitive prices. Using our own trucking and those of our well managed partners, we are able to lift clients’ goods from their premises to their customer’s premises or vice-versa in a timely and secured manner.
            </p>
            <p>
              Our “Managed Logistics” services operate on a transparent business model. Primus Global charges a management fee with full freight transparency; all savings accrue on the clients’ account. 
            </p>
          </section>


          <section className="row tm-section">      
            <div className="pm-services-container">     
                <div className="col">
                  <div className="card h-100 shadow rounded">
                    <div className="photo">
                      <img src={M1} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Custom Brokerage</h5>         
                      <p className="card-text">
                        Primus Global is licensed and have the expertise to quickly move your goods in compliance with Ghana customs and import regulations. All customs formalities carried out to get goods
                      </p>
                      <button name="m1" type="button"  id="m1" className="btn btn-danger modalBtn">
                        Show More
                      </button>  
                    </div>
                    <div className="card-footer">
                      <small className="text-muted"></small>
                    </div>      
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 shadow rounded">
                    <div className="photo">
                      <img src={M2} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Sea Freight Services</h5>         
                      <p className="card-text">
                      We move your cargo or commodities by ship, subject to availability of space with airlines and we manifest it to all sizes of cargo.
                      </p>
                      <button disabled name="m1" type="button"  id="m1" className="btn btn-danger modalBtn">
                        Show More
                      </button>  
                    </div>
                    <div className="card-footer">
                      <small className="text-muted"></small>
                    </div>      
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 shadow rounded">
                    <div className="photo">
                      <img src={M3} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Shipping Agency</h5>         
                      <p className="card-text">
                      Represent Shipping line between buyer and seller to help move goods through routes.
                      </p>
                      <button disabled name="m1" type="button"  id="m1" className="btn btn-danger modalBtn">
                        Show More
                      </button>  
                    </div>
                    <div className="card-footer">
                      <small className="text-muted"></small>
                    </div>      
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 shadow rounded">
                    <div className="photo">
                      <img src={M4} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Air Freight Services</h5>         
                      <p className="card-text">
                      We move your cargo or commodities by plane, subject to availability of space with airlines and we manifest it to all sizes of cargo.
                      </p>
                      <button disabled name="m1" type="button"  id="m1" className="btn btn-danger modalBtn">
                        Show More
                      </button>  
                    </div>
                    <div className="card-footer">
                      <small className="text-muted"></small>
                    </div>      
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 shadow rounded">
                    <div className="photo">
                      <img src={M5} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Dangerous Goods Handling</h5>         
                      <p className="card-text">
                      Primus Global have staffs who have been trained and certified by Local and International accredited I.A.T.A dangerous goods trainers to handle all types.
                      </p>
                      <button name="m1" type="button"  id="m1" className="btn btn-danger modalBtn">
                        Show More
                      </button>  
                    </div>
                    <div className="card-footer">
                      <small className="text-muted"></small>
                    </div>      
                  </div>
                </div>
            </div>
          </section>


          <Clients />
        </div>
    </div>
  )
}

export default Services