

const Fleet = () => {
  return (
    <div className="main-content-elements">
        <div className="sub-page-headers fleet-pg">
            <div>
                <section></section>
                <section></section>
                <section></section>
                <section></section>
            </div>
        </div>
        <div className="fleet-main-content">
            <p>
            Through our integrated transportation management system, we provide 
            clients with cargo planning, tendering, shipment 
            visibility and configurable event management, freight audit and payment, 
            and management reporting. 
            </p>
            <div className="fleet-details">
                <div className="fleet-section-left">
                    <section>
                        <h5>Air Cargo Handling</h5>
                        <p>Primus Global provides GSA cargo services for airlines operating in and out of Ghana. </p>
                    </section>
                    <section>
                        <h5>Customs Brokerage</h5>
                        <p>Handles all the necessary documentation regarding client’s forwarding business and also does pick-ups and delivery of cargo from client’s designated premises to the desired destination. </p>
                    </section>
                </div>
                <div className="fleet-section-center"></div>
                <div className="fleet-section-right">
                    <section>
                        <h5>Sea Cargo Handling</h5>
                        <p>Primus Global handles clients’ purchases both locally and abroad using our network of partners worldwide.</p>
                    </section>
                    <section>
                        <h5>Supply Chain Management</h5>
                        <p>Handles clients’ purchases both locally and abroad using our network of partners worldwide, this gives our clients the best quality at the most competitive prices. </p>
                    </section>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Fleet