import { Routes, Route } from "react-router-dom"
import Home from "../pages/Home"
import About from "../pages/About"
import Services from "../pages/Services"
import Contact from "../pages/Contact"
import Fleet from "../pages/Fleet"
import Error from "../pages/Error"

const MainContent = () => {
  return (
    <div className='maincontent'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<Contact />} />
          <Route path="fleet" element={<Fleet />} />
          <Route path="*" element={<Error />} />
        </Routes>
    </div>
  )
}

export default MainContent