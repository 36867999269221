import { BrowserRouter } from 'react-router-dom';
import MainContent from './components/MainContent'
import MenuPanel from './components/MenuPanel'

function App() {
  return (
    <div className="app-container">
        
        <BrowserRouter>   
          <MenuPanel />   
          <MainContent />
        </BrowserRouter>
        <div className="elfsight-app-e353ecfb-3844-496b-8826-b23227eb1854"></div>
    </div>
  );
}

export default App;
