import GTP from '../assets/images/Clients/Gtp.png'
import POM from '../assets/images/Clients/Promasidor.png'
import MAN from '../assets/images/manet.jpeg'
import SPA from '../assets/images/Clients/Spaklean.jpg'
import UNF from '../assets/images/Clients/UniqueFloral.png'

const Clients = () => {
  return (
    <section id="clients" className="row tm-section">
  <div className="">
    <div>
        <h2 className="tm-color-primary tm-section-title mb-3">OUR CLIENTS</h2>
        <p className="mb-3">
          At PGL, our hallmark is client satisfaction. To live up to this, we offer full logistics solutions in ways that relieve out clients of all the stress associated with moving goods from one place to another, through all known mediums. 
        </p>
    </div>
    
    <div className="row tm-clients-images">
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
        <a href="https://google.com">
          <img src={GTP} alt="Client Image" className="img-fluid tm-client-img" />
        </a>                          
      </div>
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
        <a href="https://facebook.com">
          <img src={POM} alt="Client Image" className="img-fluid tm-client-img" />
        </a>                          
      </div>
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
        <a href="https://twitter.com">
          <img src={MAN} alt="Client Image" className="img-fluid tm-client-img" />
        </a>                          
      </div>
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
        <a href="https://instagram.com">
          <img src={SPA} alt="Client Image" className="img-fluid tm-client-img" />
        </a>                          
      </div>
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 tm-img-wrap">
        <a href="https://instagram.com">
          <img src={UNF} alt="Client Image" className="img-fluid tm-client-img" />
        </a>                          
      </div>
    </div>



    {/* <div className='container'>
      <div className='logo-slider'>
        <div className='item'><a href="#"><img src={GTP} alt=""/></a></div>
        <div className='item'><a href="#"><img src={POM} alt=""/></a></div>
        <div className='item'><a href="#"><img src={MAN} alt=""/></a></div>
        <div className='item'><a href="#"><img src={SPA} alt=""/></a></div>
        <div className='item'><a href="#"><img src={UNF} alt=""/></a></div>
      </div>
    </div> */}

</div>                           
</section>
  )
}

export default Clients