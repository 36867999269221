import Map from '../components/Map'
import Destinations from '../components/Destinations'

const About = () => {
  return (
    <div className="main-content-elements">
        <div>         
          <div className="sub-page-headers about-pg">
              {/* HEADER SECTION */}
          </div>
          <div className="sub-page-content">
            <section className="pm-section-abt">
              <div>    
                  <h2 className="tm-section-title tm-color-primary mb-4">ABOUT US</h2>
                  <p className="mb-2">
                    PRIMUS GLOBAL LIMITED is an international logistics company 
                    operating in Ghana, offering premium logistics services to 
                    businesses worldwide.
                    Our personal services, expert knowledge of cargo handling, 
                    freight and customs brokerage is unrivaled.
                  </p>
                  <p>
                    Founded in October, 2015, by two partners, this new company has 
                    absolved the business of J&J Logistics Limited after 5 years 
                    operation in a successful restructuring. The company commenced 
                    business in the same month and has since impacted positively on 
                    cargo sales. It is a wholly owned Ghanaian business. It's office
                    are located at the Kotoka International Airport with Tema serving as the 
                    Corporate Office.
                    Primus Global has a world-wide network with strong connections in the 
                    West African Sub-Region, Southern Africa, India, Uk, and USA.
                  </p>
              </div>
              <div className='pm-jo'></div>
            </section>
            <section>
              <div className='pm-section-ouv'>
                  <div className='pm-shared-partners'>
                    <div>
                      <div className='pm-jo'></div>
                      <div className='pm-ja'></div>
                    </div>
                  </div>
                  <div className='pm-ja'></div>
                  <div>
                    <div className="tm-section-half">    
                      <h2 className="tm-section-title tm-color-primary mb-4">OUR VALUES</h2>
                      <p className="mb-2">
                        Unique and creative logistic solutions that meet the clients’ 
                        expectations not only by realizing the clients’ business objectives, 
                        but particularly  tailored to strict adherence to the ethical principles of; 
                      </p>
                      <p>
                        <ul>
                          <li>
                            <span><div></div></span>
                            Team work based on the implementation of progressive communications disciplines
                          </li>
                          <li>
                            <span><div></div></span>
                            Professional quality of services provided by a highly motivated team</li>
                          <li>
                            <span><div></div></span>
                            Strong and competent management team who drive the day-to-day business operations employing modern ICT. </li>
                          <li>
                            <span><div></div></span>
                            Provide non-core but essential logistics support to our clients at very competitive prices with no hassle. </li>
                          <li>
                            <span><div></div></span>
                            At Primus, we believe our clients should focus on their core business whilst we provide them with an excellent professional service in the area of logistics management. </li>
                          <li>
                            <span><div></div></span>
                            Our passion is to delight our clients with first class solutions and deliver value to them. </li>
                          <li>
                            <span><div></div></span>
                            Primus relies on the competences of its highly qualified and motivated personnel and our world-wide network of associates to deliver on our promises 24/7.</li>
                        </ul>                             
                      </p>
                    </div>
                  </div>
              </div>
            </section>
            <section>
              <div>
                <h2 className="tm-section-title tm-color-primary mb-2">WHERE ARE YOUR OFFICES?</h2>
                <p className="mb-4">
                  Our well-furnished and equipped office is situated in the heart of Kotoka International Airport next to the Domestic Terminal. Cargo D’or, Room #2
                  We operate from the three main ports of <b>Ghana</b>; Kotoka International Airport, Tema Harbour. We have agency representation at all the remaining entry points of Ghana.
                  Our Head Office is situated at Maxmens Plaza,
                  13 Chapel Square, 1st Floor.
                  Sakumono, Accra. and
                </p>
                <p className="mb-4">
                  Our <b>Benin</b> office is situated at, Cotonou. 11 Avenue Jean Paul II "Les Cocotiers" Lot G27
                </p>
                <h2 className="tm-section-title tm-color-primary mb-2">ARE YOU REGISTERED AND LICENSED TO OPERATE?</h2>
                <p className="mb-4">
                  Primus Global is a Customs approved Forwarding and Clearing Company and also a member of the Ghana Institute of Freight Forwarders (GIFF).
                </p>
                <h2 className="tm-section-title tm-color-primary mb-2">HOW BIG IS YOUR TEAM?</h2>
                <p>
                  Primus Global is managed by a five-member Board of Directors with rich experience and diverse background in supply chain management, cargo handling, financing and business management.
                  The Board is supported by a strong management team who drive the day-to-day activities of the Company.
                </p>
              </div>

              <Destinations />

            </section>
            
          </div>
          <Map />
        </div>
    </div>
  )
}

export default About