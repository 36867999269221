import HomeVideo from '../assets/videos/PHBanner.mp4'
import Plane from '../assets/images/plane.jpg'

const Home = () => {
    window.onload = function() {
        document.getElementById("autoplay").play()
    }
  return (
    <>
        <video loop muted id="autoplay" className='bannervid' poster={Plane}>
            <source src={HomeVideo} type="video/mp4" />
            Your browser does not support video tag.
        </video>
        <div className='home-right-panel'>
        </div>  
    </>
  )
}

export default Home